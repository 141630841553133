import { useContext, useEffect, useRef } from "react";
import { IGlobalAnimationProps } from "./GlobalAnimation.interface";
import { LoadingContext } from "../../shared/providers/loading/loading.provider";
import Header from "../Header/Header";
import { APP_CONFIGS } from "../../shared/constants";
import { motion } from "framer-motion";
import Footer from "../Footer/Footer";

const variants = {
  hidden: { opacity: 0 },
  enter: { opacity: 1 },
  exit: { opacity: 0 },
};

function GlobalAnimation({ children }: IGlobalAnimationProps) {

  return (
      <motion.div
        initial="hidden"
        animate="enter"
        exit="exit"
        variants={variants}
        transition={{ duration: 0.2, type: "easeInOut" }}
        className="animation_wrapper"
      >
        {children}
      </motion.div>
  );
}

export default GlobalAnimation;
