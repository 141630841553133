import GlobalAnimation from "../../components/GlobalAnimation/GlobalAnimation";
import GlobalLayout from "../../components/GlobalLayout/GlobalLayout";
import Link from "../../components/Link/Link";
import { APP_CONFIGS } from "../../shared/constants";
import { routes } from "../../shared/router/routes";

function Home() {
  return (
    <GlobalAnimation>
      <div className="page-home container">
        <div className="row h-100">
          <div className="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column">
            <div className="d-flex flex-column gap-2 me-1 align-items-end page_content">
              <h1 className="page_title text-headline-bd text-headline-bd-lg mx-auto ms-md-auto">
                <span>Duarte</span>
                <span>&</span>
                <span>Mariana</span>
              </h1>
              <span className="date">15 de Agosto, 2025</span>
              <Link variant="solid" to={routes.CHECKIN.path} label="Check In" />
            </div>
          </div>
          <figure className="col-12 col-md-6 page_figure">
            <img
              src={APP_CONFIGS.pages.HOME.image}
              alt={APP_CONFIGS.pages.HOME.image_alt}
            />
          </figure>
        </div>
      </div>
    </GlobalAnimation>
  );
}

export default Home;
