import { useEffect, useRef, useState } from "react";
import {
  ISelectProps,
  ISelectOptionsProps,
  ISelectOption,
} from "./Select.interface";
import { delay } from "../../../shared/helpers/utils";
import { IDropdownPlacement } from "../../Dropdown/Dropdown.interface";

function Select({
  options,
  value: currentValue,
  placeholder,
  label,
  onChange,
  errors,
}: ISelectProps) {
  const DEFAULT_PLACEMENT = "bottom right";
  const DEFAULT_OFFSET = 150;

  const selectRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [option, setOption] = useState<ISelectOption>();
  const [placement, setPlacement] = useState<IDropdownPlacement | undefined>();
  const pickerElRef = useRef<HTMLDivElement>(null);
  const optionsElRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    setOption(options?.find((op) => op.value === currentValue));
  }, [currentValue, options]);

  useEffect(() => {
    if (!optionsElRef.current || !isOpen) return;

    const coords = optionsElRef.current?.getBoundingClientRect();
    let place = "";

    if (coords.top < 0) place += "bottom";
    else if (
      coords.top > 0 &&
      coords.bottom + DEFAULT_OFFSET >= window.innerHeight
    )
      place += "top";

    if (coords.left >= 0 && coords.right + DEFAULT_OFFSET >= window.innerWidth)
      place += " right";
    else place += " left";

    if (place === "") place = DEFAULT_PLACEMENT;

    setPlacement(place as IDropdownPlacement);
  }, [isOpen]);

  useEffect(() => {
    if (!option || !onChange || option.value === currentValue) return;
    onChange(option);
  }, [option]);

  return (
    <div className="form_field" ref={selectRef}>
      {label && (
        <button
          type="button"
          className="form_field-label"
          onClick={() => setIsOpen(true)}
        >
          {label}
        </button>
      )}
      <div className="form_field-select" ref={pickerElRef}>
        <button
          type="button"
          className={`
                select-btn text-start
                ${isOpen ? "is_active" : ""}
                ${option?.label ? "color-neutrals-lighter" : "color-neutrals-neutral"}
                ${errors?.length ? "has_error" : ""}
            `}
          aria-pressed={isOpen}
          onClick={() => setIsOpen((v) => !v)}
        >
          {option?.label ?? placeholder}
          <i className="select-icon"></i>
        </button>
        {isOpen && (
          <SelectOptions
            selectRef={selectRef}
            option={option}
            setOption={setOption}
            options={options}
            setIsOpen={setIsOpen}
            placement={placement}
            dropdownRef={optionsElRef}
          />
        )}
      </div>
      {errors &&
        errors.map((error, i) => (
          <span key={i} className="d-block mt-02 color-light-red text-bd3-md">
            {error}
          </span>
        ))}
    </div>
  );
}

function SelectOptions({
  selectRef,
  setIsOpen,
  placement,
  dropdownRef,
  options,
  option: currentValue,
  setOption,
}: ISelectOptionsProps) {
  useEffect(() => {
    function clickOutside(e: Event) {
      const clickedEl = e.target as HTMLElement;
      if (selectRef.current?.contains(clickedEl)) return;
      setIsOpen(false);
    }

    async function onBlur() {
      await delay(100);
      if (selectRef.current?.contains(document.activeElement)) return;
      setIsOpen(false);
    }

    window.addEventListener("keydown", onBlur);
    window.addEventListener("click", clickOutside);

    return () => {
      window.removeEventListener("click", clickOutside);
      window.removeEventListener("keydown", onBlur);
    };
  }, [selectRef, setIsOpen]);

  return (
    <ul
      className={`select-options ${placement ? placement : "opacity-0"}`}
      ref={dropdownRef}
    >
      {options.map((option) => (
        <li
          aria-current={currentValue?.value === option.value}
          key={option.value}
        >
          <button
            type="button"
            className={`${currentValue?.value === option.value ? "is_active" : ""}`}
            onClick={() => {
              setOption(option);
              setIsOpen(false);
            }}
          >
            {option.label}
          </button>
        </li>
      ))}
    </ul>
  );
}

export default Select;
