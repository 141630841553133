import { ChangeEvent, useEffect, useState } from "react";
import { IIconButton, IInputTextProps } from "./InputText.interface";

function InputText({
    label,
    value: inputValue,
    type,
    placeholder,
    name,
    onChange: onInputChange,
    errors,
    totalSteps,
    currentStep,
    stepLabel,
    customCSS,
    buttonIcons,
    ...props
}: IInputTextProps) {
    const [value, setValue] = useState<string | number | readonly string[]>("");

    useEffect(() => {
        if (!inputValue && inputValue !== "") return;

        setValue(inputValue);
    }, [inputValue]);

    const IconButton = ({ type = "button", action, icon, placement = "start" }: IIconButton) => (
        <button
            type={type}
            disabled={props.disabled}
            onClick={action}
            className={`input_action color-neutrals-neutral on_${placement}`}>
            <i className={`icon icon-24 fa-${icon}`}></i>
        </button>
    );

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (onInputChange) onInputChange(e);
        setValue(e.target.value);
    };

    return (
        <label className="form_field">
            {label && <span className="form_field-label">{label}</span>}
            <div className="form_field-actions_wrapper">
                {<input
                    {...props}
                    value={value}
                    type={type}
                    className={`form_field-input ${errors?.length ? "has_error" : ""} ${customCSS}`}
                    placeholder={placeholder}
                    name={name}
                    onChange={onChange}
                    />}
                {buttonIcons?.map((icon, i) => <IconButton {...icon} key={i} />)}
            </div>
            {totalSteps && (
                <div
                    className="d-flex gap-01 mt-n02"
                    aria-label={`${Math.round(((currentStep ?? 0) / totalSteps) * 100)}% Completed`}>
                    {Array(totalSteps)
                        .fill(0)
                        .map((_, i) => (
                            <span
                                key={i}
                                className={`form_field-step ${i < (currentStep ?? 0) ? "is_active" : ""}`}
                                aria-hidden></span>
                        ))}
                </div>
            )}
            {stepLabel && totalSteps && (
                <span className="d-flex gap-01 mt-n02 justify-content-end color-red-600 text-bd4-md">
                    {stepLabel}
                </span>
            )}
            {errors &&
                errors.map((error, i) => (
                    <span key={i} className="d-block mt-02 color-light-red text-bd3-md">
                        {error}
                    </span>
                ))}
        </label>
    );
}

export default InputText;
