export const APP_CONFIGS = {
  title: "Craveiro Chen",
  pages: {
    HOME: {
      title: "Home",
      icon: "",
      path: "/",
      image: "/assets/imgs/2.jpg",
      image_alt: "Fotografia dos noivos",
    },
    CHECKIN: {
      path: "/checkin",
      title: "Check In",
      image: "/assets/imgs/3.JPG",
      image_alt: "Fotografia dos noivos",
    },
    ITINERARY: { path: "/itineary", title: "Itinerário" },
    HELP: { path: "/contacts", title: "Contactos" },
  },
};

export const CONTENT_BASE_URL = process.env.REACT_APP_BASE_CONTENT_API_URL;
