import { createContext, useState } from "react";
import { ILayoutContext, ILayoutProps } from "./layout.interface";

const loadingInitValues: ILayoutContext = {
  navPosition: "left",
  setNavPosition: () => {},
};

export const LayoutContext = createContext<ILayoutContext>(loadingInitValues);

const LayoutProvider = ({ children }: ILayoutProps) => {
  const [navPosition, setNavPosition] = useState<"left" | "center" | "right">(
    "left",
  );

  return (
    <LayoutContext.Provider value={{ navPosition, setNavPosition }}>
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;
