import { useContext, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import { IHeaderProps } from "./Header.interface";
import { LayoutContext } from "../../shared/providers/layout/layout.provider";
import { useLocation } from "react-router-dom";
import { routes } from "../../shared/router/routes";

function Header(props: IHeaderProps) {
  const { navPosition, setNavPosition } = useContext(LayoutContext);
  const location = useLocation();

  const navPos = (position: string) => {
    switch (position) {
      case "left":
        return "col-12 col-md-6";
      case "right":
        return "col-12 col-md-6 col-lg-5 offset-md-6 offset-lg-7 justify-content-md-start";
      case "center":
        return "col-12 col-md-6 offset-md-3";
      default:
        break;
    }
  };

  useEffect(() => {
    if (location.pathname.match(routes.CHECKIN.path)) setNavPosition("right");
    else if (location.pathname.match(routes.HOME.path)) setNavPosition("left");
  }, [location.pathname]);

  return (
    <header className="header">
      <div className="container py-1 gx-0 gx-sm-1">
        <div className="row gx-0 gx-md-4">
          <div
            className={`${navPos(navPosition)} d-flex justify-content-center`}
          >
            <Navbar />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
