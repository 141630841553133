import GlobalAnimation from "../../components/GlobalAnimation/GlobalAnimation";

function Itinerary() {
  return (
    <GlobalAnimation>
      <p className="page-itinerary">itinerary</p>
    </GlobalAnimation>
  );
}

export default Itinerary;
