import { useLocation, useOutlet } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { cloneElement } from "react";
import GlobalLayout from "../../components/GlobalLayout/GlobalLayout";

function Index() {
  const location = useLocation();
  const outlet = useOutlet();
  return (
    <GlobalLayout>
      <AnimatePresence mode="wait" initial={true}>
        {outlet &&
          cloneElement(outlet, {
            key: location.pathname,
          })}
      </AnimatePresence>
    </GlobalLayout>
  );
}

export default Index;
