import { Link, useLocation } from "react-router-dom";
import { routes } from "../../shared/router/routes";
import { useMemo } from "react";

function Navbar() {
  const location = useLocation();
  const menu = useMemo(() => Object.entries(routes).map((t) => t[1]), []);

  return (
    <nav className="navbar">
      <ul className="menu">
        {menu.map((item: any, i: number) => (
          <li
            key={i}
            className={`text-bd3-bd text-bd2-md-lg ${item.path === location.pathname ? "is_active" : ""}`}
          >
            <Link to={item.path}>
              {item.icon && (
                <i className={`icon icon-24 icon-${item.icon}`}></i>
              )}
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Navbar;
