import { useContext, useEffect } from "react";
import { IGlobalLayoutProps } from "./GlobalLayout.interface";
import { LoadingContext } from "../../shared/providers/loading/loading.provider";
import Header from "../Header/Header";
import { APP_CONFIGS } from "../../shared/constants";
import Footer from "../Footer/Footer";
import Loading from "../Loading/Loading";

function GlobalLayout({ children }: IGlobalLayoutProps) {
  const { isLoading } = useContext(LoadingContext);

  useEffect(() => {
    document.title = APP_CONFIGS.title;
  }, []);

  return (
      <div className="layout pb-3">
        {isLoading && <Loading />}
        <Header />
        <main>{children}</main>
        <Footer />
      </div>
  );
}

export default GlobalLayout;
