import { APP_CONFIGS } from "../../shared/constants";
import GlobalAnimation from "../../components/GlobalAnimation/GlobalAnimation";
import { useFormik } from "formik";
import InputText from "../../components/Forms/InputText/InputText";
import Select from "../../components/Forms/Select/Select";
import Textarea from "../../components/Forms/Textarea/Textarea";
import Button from "../../components/Button/Button";
import { useContext, useEffect, useState } from "react";
import { IGuest } from "../../shared/interfaces/guests.interface";
import * as Yup from "yup";
import { useSendGuest } from "../../shared/hooks/useContactForm";
import { LoadingContext } from "../../shared/providers/loading/loading.provider";
import { delay } from "../../shared/helpers/utils";
import { LayoutContext } from "../../shared/providers/layout/layout.provider";
import Link from "../../components/Link/Link";
import { routes } from "../../shared/router/routes";

function Checkin() {
  const { setNavPosition } = useContext(LayoutContext);
  const { setIsLoading } = useContext(LoadingContext);
  const [edit, setEdit] = useState<number>();
  const [step, setStep] = useState<number>(0);
  const [guests, setGuests] = useState<IGuest[]>([]);
  const sendGuest = useSendGuest();

  useEffect(() => {
    setNavPosition("right");
  }, []);

  const form = useFormik({
    initialValues: {
      name: "",
      ageGroup: "",
      foodRestrictions: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Adicione o nome do convidado"),
      ageGroup: Yup.string().required("Adicione a faixa etária"),
      foodRestrictions: Yup.string(),
    }),
    onSubmit: (values: IGuest) => {
      if (edit) {
        setGuests((p) => {
          p[edit - 1] = values;
          return p;
        });
        setEdit(undefined);
      } else {
        setGuests((p) => [...p, values]);
      }
      setStep(0);
      form.resetForm();
    },
  });

  const handleSubmitForm = async () => {
    setIsLoading(true);
    await Promise.all(guests.map((g) => sendGuest.mutateAsync(g)));
    await delay(500);
    setGuests([]);
    setIsLoading(false);
    setStep(2);
  };

  const handleCancelForm = () => {
    form.resetForm();
    setStep(0);
  };

  return (
    <GlobalAnimation>
      <div className="page-checkin">
        <div className="container-fluid gx-0">
          <div className="row gx-0">
            <figure className="page_figure col-12 col-md-6">
              <img
                src={APP_CONFIGS.pages.CHECKIN.image}
                alt={APP_CONFIGS.pages.CHECKIN.image_alt}
              />
            </figure>
          </div>
        </div>
        <div className="container page_content">
          <div className="row h-100">
            <div className="col-12 col-md-6 col-lg-5 offset-md-6 offset-lg-7 pt-12 pt-md-5 d-flex flex-column gap-1 gap-md-11">
              <h1 className="text-h2-bd-md text-h3-bd text-uppercase">
                {APP_CONFIGS.pages.CHECKIN.title}
              </h1>
              <p className="text-bd2-md">
                Adicione todos os seus acompanhantes e confirme a sua presença
                no nosso dia tão especial.
              </p>
              <div className="mt-3">
                {step === 0 && (
                  <div className="d-flex flex-column gap-3 align-items-start">
                    {!!guests.length && (
                      <ul className="guest_list d-flex flex-column w-100 mt-n02">
                        {guests.map((g, index) => (
                          <li
                            key={index}
                            className="py-02 d-flex justify-content-between align-items-center"
                          >
                            {g.name}
                            <div className="d-flex gap-02">
                              <Button
                                type="button"
                                customCss="px-02"
                                icon="create"
                                onClick={() => {
                                  setEdit(index + 1);
                                  setStep(1);
                                  form.setValues(g);
                                }}
                              />
                              <Button
                                type="button"
                                customCss="px-02"
                                icon="clear"
                                onClick={() =>
                                  setGuests((p) =>
                                    p.filter((_, t) => t !== index),
                                  )
                                }
                              />
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                    <div className="d-flex justify-content-end gap-1 w-100">
                      <Button
                        type="button"
                        label={
                          guests.length === 0
                            ? "Adicionar convidado"
                            : "Adicionar"
                        }
                        icon="add"
                        variant={guests.length === 0 ? "solid" : "outlined"}
                        customCss={guests.length === 0 ? "mx-auto" : ""}
                        onClick={() => setStep(1)}
                        isIconRight
                      />
                      {!!guests.length && (
                        <Button
                          type="button"
                          label="Enviar"
                          icon="send"
                          variant="solid"
                          onClick={() => handleSubmitForm()}
                          isIconRight
                        />
                      )}
                    </div>
                  </div>
                )}
                {step === 1 && (
                  <form
                    className="d-flex flex-column gap-12"
                    onSubmit={form.handleSubmit}
                  >
                    <InputText
                      type="text"
                      label="Nome completo"
                      placeholder="Nome"
                      name="name"
                      value={form.values.name}
                      onChange={form.handleChange}
                      errors={
                        form.submitCount > 0 ? form.errors.name?.split("#") : []
                      }
                    />
                    <Select
                      options={[
                        { label: "Até aos 3 anos", value: "-3" },
                        { label: "3 aos 9 anos", value: "3-9" },
                        { label: "Maiores de 9 anos", value: "adulto" },
                      ]}
                      placeholder="Selecione a sua faixa etária"
                      value={form.values.ageGroup}
                      label="Faixa etária"
                      onChange={(e) => form.setFieldValue("ageGroup", e.value)}
                      errors={
                        form.submitCount > 0
                          ? form.errors.ageGroup?.split("#")
                          : []
                      }
                    />
                    <Textarea
                      label="Restrições alimentares"
                      placeholder="Adicione as suas restrições alimentares"
                      name="foodRestrictions"
                      value={form.values.foodRestrictions}
                      onChange={form.handleChange}
                    />
                    <div className="d-flex gap-03 justify-content-end align-items-center">
                      <Button
                        type="reset"
                        label="Cancelar"
                        variant="outlined"
                        onClick={handleCancelForm}
                      />
                      <Button
                        type="submit"
                        label={edit ? "Atualizar" : "Adicionar"}
                        variant="solid"
                        icon="add"
                        isIconRight
                      />
                    </div>
                  </form>
                )}
                {step === 2 && (
                  <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                    <i className="icon icon-100 icon-check_circle"></i>
                    <p className="text-bd2-md text-center">
                      A sua confirmação foi enviada. Irá receber um email com a
                      confirmar a sua presença.
                    </p>
                    <Link variant="outlined" label="Voltar" to={routes.HOME.path} icon="arrow_back" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </GlobalAnimation>
  );
}

export default Checkin;
