import { ISelectOption } from "../../components/Forms/Select/Select.interface";
import { IGuest } from "../interfaces/guests.interface";
import { IRequest } from "../interfaces/requests.interface";

export const sendGuest = async (
  guest: IGuest,
): Promise<IRequest<ISelectOption[]>> => {
  var formdata = new FormData();
  formdata.append("entry.1787281768", guest.name);
  formdata.append("entry.566312909", guest.ageGroup);
  formdata.append("entry.1549087346", guest.foodRestrictions);
  try {
    fetch(
      "https://docs.google.com/forms/u/0/d/e/1FAIpQLScGdKHGxiYEl8K_Dgy3_3sA-eLApsFECc7BIFYgKfoptxuJew/formResponse",
      {
        method: "POST",
        body: formdata,
      },
    ).catch((e) => console.log(e));
    return { success: true, data: [] };
  } catch (e: any) {
    console.log(e.message)
    return { success: false, data: [] as any };
  }
};
